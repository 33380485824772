import { useKeycloak } from 'use-keycloak';

export default callback => {
  const config = {
    realm: 'vw',
    url: process.env.GATSBY_US_URL,
    clientId: 'testedgenuine',
    enableLogging: false,
    onLoad: 'check-login',
    extraQueryParams: [['themeid', 'neutral']],
    scope: 'openid email profile address phone phone_required',
    redirectUri: `${process.env.GATSBY_US_REDIRECT}`,
    // useDangerCookies: true, // always OFF for PROD !!! only for development !!!
  };

  const authData = useKeycloak(config);

  if (authData) {
    callback(authData);
  }

  return null;
};
