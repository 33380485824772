import { useContext } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import isNode from 'detect-node';
import Cookie from 'js-cookie';
import useKeycloak from '../../../hooks/useKeycloak';

const Authorization = () => {
  const {
    state: { auth, token },
    dispatch,
  } = useContext(MainContext);

  const updateToken = updatedToken => {
    dispatch({ type: 'token', payload: updatedToken });
  };

  if (!auth || !token) {
    useKeycloak(authData => {
      if (authData) {
        if (authData.authToken !== '') {
          updateToken(authData.authToken);

          setTimeout(async () => {
            const updatedToken = await authData.getRefreshedToken();
            updateToken(updatedToken);
          }, 1000 * 60 * 5); // 5 min
        }

        if (!auth) {
          dispatch({
            type: 'auth',
            payload: {
              login: authData.login,
              register: authData.register,
              logout: authData.logout,
            },
          });
        }
      }
    });
  } else {
    if (isNode) return null;

    if (Cookie.get('VW_AUTH')) {
      const appUrl = process.env.GATSBY_US_REDIRECT;
      const authPath = Cookie.get('VW_AUTH') || appUrl;
      Cookie.remove('VW_AUTH');
      return window.location.replace(authPath);
    }
  }

  return null;
};

export default Authorization;
