import { createGlobalStyle } from 'styled-components';
import { media } from './Theme';

const GlobalStyles = createGlobalStyle`

  
  html,body{
    margin: 0;
    padding: 0,
  }
  html{
    font-size: 10px;
  }
  body{
    font-size: 18px;
    ${media.tablet`
    font-size:20px;
    `}
    font-family: ${props => props.theme.fonts.font}, 'VW-Head', sans-serif;
    line-height: 27px;
    color: #004666;
    box-sizing:border-box;
  }
  *{
    box-sizing: inherit;
  }
  h1,h2,h3,h4,h5,h6{
    font-weight:400;
    font-family: ${props => props.theme.fonts.font_head}, 'VW-Head', serif;
  }
  h1{
    font-size:${({ theme }) => theme.headings.h1.fontSize.default}rem;
    ${media.tablet`
    font-size:${({ theme }) => theme.headings.h1.fontSize.tablet}rem;
    `}
    ${media.desktop`
    font-size:${({ theme }) => theme.headings.h1.fontSize.desktop}rem;
    `}
    ${media.widescreen`
    font-size:${({ theme }) => theme.headings.h1.fontSize.widescreen}rem;
    `}
  }
  h2{
    font-size:${({ theme }) => theme.headings.h2.fontSize.default}rem;
    ${media.tablet`
    font-size:${({ theme }) => theme.headings.h2.fontSize.tablet}rem;
    `}
    ${media.desktop`
    font-size:${({ theme }) => theme.headings.h2.fontSize.desktop}rem;
    `}
    ${media.widescreen`
    font-size:${({ theme }) => theme.headings.h2.fontSize.widescreen}rem;
    `}
  }
  h3{
    font-size:${({ theme }) => theme.headings.h3.fontSize.default}rem;
    ${media.tablet`
    font-size:${({ theme }) => theme.headings.h3.fontSize.tablet}rem;
    `}
    ${media.desktop`
    font-size:${({ theme }) => theme.headings.h3.fontSize.desktop}rem;
    `}
    ${media.widescreen`
    font-size:${({ theme }) => theme.headings.h3.fontSize.widescreen}rem;
    `}
  }
  h4{
    font-size:${({ theme }) => theme.headings.h4.fontSize.default}rem;
    ${media.tablet`
    font-size:${({ theme }) => theme.headings.h4.fontSize.tablet}rem;
    `}
    ${media.desktop`
    font-size:${({ theme }) => theme.headings.h4.fontSize.desktop}rem;
    `}
    ${media.widescreen`
    font-size:${({ theme }) => theme.headings.h4.fontSize.widescreen}rem;
    `}
  }
  h5{
    font-size:${({ theme }) => theme.headings.h5.fontSize.default}rem;
    ${media.tablet`
    font-size:${({ theme }) => theme.headings.h5.fontSize.tablet}rem;
    `}
    ${media.desktop`
    font-size:${({ theme }) => theme.headings.h5.fontSize.desktop}rem;
    `}
    ${media.widescreen`
    font-size:${({ theme }) => theme.headings.h5.fontSize.widescreen}rem;
    `}
  }
  h6{
    font-size:${({ theme }) => theme.headings.h6.fontSize.default}rem;
    ${media.tablet`
    font-size:${({ theme }) => theme.headings.h6.fontSize.tablet}rem;
    `}
    ${media.desktop`
    font-size:${({ theme }) => theme.headings.h6.fontSize.desktop}rem;
    `}
    ${media.widescreen`
    font-size:${({ theme }) => theme.headings.h6.fontSize.widescreen}rem;
    `}
  }
  .react-slidedown {
    transition-duration: .2s !important;
}
`;

export default GlobalStyles;
