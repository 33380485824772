import { css } from 'styled-components';

const sizes = {
  mobile: 360,
  phablet: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
  widescreen: 1486,
  ultrawidescreen: 1921,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

const zeroLetterSpacing = {
  default: 0,
};

const Theme = {
  primary: '#8dbecf',
  primary_blocks: '#ddecf1',
  primary_petrol: '#004666',
  secondary_blue_dark: '#006384',
  secondary_blue_light: '#80b0c8',
  secondary_petrol: '#c6dfe7',
  petrol60: '#67879d',
  petrol80: '#335f7d',
  red: '#a21e4d',
  red_light: '#d08fa6',
  grey: '#d2d5d9',
  grey70: '#7f8789',
  primary_grey: '#4c5356',
  silver: '#a8adb3',
  green: '#32a24a',
  green_light: '',
  black: '#34393a',
  white: '#ffffff',
  greyBgText: '#f4f6f8',
  formSecond: '#eef5f8',
  checkBox_grey: '#d3d6d9',
  bg_color: '#f4f9fa',
  anthracite: '#34393b',
  orange_dark: '#d8aa00',
  orange_dark50: '#ebd47f',

  fonts: {
    font: 'VW-Sans Light',
    font_bold: 'VW-Sans Bold',
    font_head: 'VWAGTheAntiqua',
  },

  radius: '4px',

  animations: {
    duration: '.3s',
    trans_basis: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    trans_enter: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    trans_exit: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    trans_linear: 'linear',
    animation_duration: '.15s',
  },

  gridMaxWidths: {
    sm: '960px',
    md: '960px',
    lg: '960px',
    xl: '100%',
  },

  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1280,
  },

  gridGutter: 8,
  gridColumns: 12,

  headings: {
    h1: {
      fontSize: {
        default: 2.4,
        phablet: 3,
        tablet: 3,
        laptop: 3,
        desktop: 3,
        widescreen: 4.6,
      },
      lineHeight: {
        default: 33,
        phablet: 39,
        tablet: 48,
        laptop: 65,
      },
      letterSpacing: {
        default: -2.5,
      },
    },
    h2: {
      fontSize: {
        default: 2.3,
        phablet: 2.6,
        tablet: 3.2,
        laptop: 4.5,
      },
      lineHeight: {
        default: 27,
        phablet: 30,
        tablet: 40,
        laptop: 50,
      },
      letterSpacing: {
        default: -2.5,
      },
    },
    h3: {
      fontSize: {
        default: 2.1,
        laptop: 2.8,
        tablet: 3.5,
      },
      lineHeight: {
        default: 25,
        laptop: 33,
      },
      letterSpacing: {
        default: -2,
      },
    },
    h4: {
      fontSize: {
        default: 1.8,
        laptop: 2.4,
        tablet: 3.5,
      },
      lineHeight: {
        default: 22,
        laptop: 29,
      },
      letterSpacing: {
        default: -1.5,
      },
    },
    h5: {
      fontSize: {
        default: 1.6,
        laptop: 1.8,
        tablet: 3.5,
      },
      lineHeight: {
        default: 20,
        laptop: 23,
      },
      letterSpacing: {
        default: -1.2,
      },
    },
    h6: {
      fontSize: {
        default: 1.6,
        laptop: 1.8,
        tablet: 3.5,
      },
      lineHeight: {
        default: 20,
        laptop: 23,
      },
      letterSpacing: {
        default: -1.2,
      },
    },
  },
  text: {
    b1: {
      fontSize: {
        default: 15,
        laptop: 16,
      },
      lineHeight: {
        default: 23,
        laptop: 25,
      },
      letterSpacing: zeroLetterSpacing,
    },
    b2: {
      fontSize: {
        default: 14,
      },
      lineHeight: {
        default: 22,
      },
      letterSpacing: zeroLetterSpacing,
    },
    b3: {
      fontSize: {
        default: 14,
      },
      lineHeight: {
        default: 22,
      },
      letterSpacing: zeroLetterSpacing,
    },
    b4: {
      fontSize: {
        default: 11,
      },
      lineHeight: {
        default: 16,
      },
      letterSpacing: zeroLetterSpacing,
    },
  },
};

export default Theme;
