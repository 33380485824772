/* eslint-disable func-names */
import isNode from 'detect-node';

if (!isNode) {
  window.requestAnimFrame = (() =>
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    })();
}
