import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import es6Promise from 'es6-promise';
import GlobalStyles from './Styles/GlobalStyles';
import { MainContextProvider } from './MainContextProvider';
import Authorization from '../components/Common/Authorization';
import Theme from './Styles/Theme';
import './Styles/fonts.css';
import './scripts';
import TechworkPage from 'Pages/techwork';

es6Promise.polyfill();
require('isomorphic-fetch');

const TECH_WORK_ACTIVE = false;

const App = ({ children, lang }) => {
  return (
    <MainContextProvider lang={lang}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        {TECH_WORK_ACTIVE ? (
          <TechworkPage />
        ) : (
          <>
            <Authorization />
            {children}
          </>
        )}
      </ThemeProvider>
    </MainContextProvider>
  );
};

export default App;
