import React, { useReducer, createContext, useEffect } from 'react';
import Cookie from 'js-cookie';

const Context = createContext();

const initialState = {
  auth: null,
  token: null,
  agree: false,
  showDropDown: false,
  playVideo: null,
  showTerms: false,
  showConsent: false,
  lang: 'ru',
  email: null,
  userId: null,
  users: [
    'Anna.Zimina@volkswagen.ru',
    'Ekaterina.Leonova@volkswagen.ru',
    'aby@kodix.ru',
    'ros@kodix.ru',
    'zp@kodix.ru',
    'lev718@list.ru',
    'yury.titov@volkswagen.ru',
    'Ramil.Gasanov@volkswagen.ru',
    'Olga.Kostuchenko@volkswagen.ru',
    'bs@kodix.ru',
    'Alena.Khramchenkova@vwgroup.ru',
    'rus@kodix.ru'
  ],
  isShowStatisticsInMenu: false,
  page: null,
};
function reducer(state, action) {
  switch (action.type) {
    case 'toggleDropDown':
      return { ...state, showDropDown: !state.showDropDown };
    case 'toggleLanguage':
      return { ...state, lang: action.payload };
    case 'playVideo':
      return { ...state, playVideo: action.payload };
    case 'token':
      return { ...state, token: action.payload };
    case 'auth':
      return { ...state, auth: action.payload };
    case 'agree':
      return { ...state, agree: action.payload };
    case 'showTerms':
      return { ...state, showTerms: action.payload };
    case 'showConsent':
      return { ...state, showConsent: action.payload };
    case 'username':
      return { ...state, username: action.payload };
    case 'email':
      return { ...state, email: action.payload };
    case 'userId':
      return { ...state, userId: action.payload };
    case 'isShowStatisticsInMenu':
      return { ...state, isShowStatisticsInMenu: action.payload };
    case 'page':
      return { ...state, page: action.payload };
    default:
      throw new Error();
  }
}
const MainContextProvider = ({ children, lang }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (lang !== state.lang) dispatch({ type: 'toggleLanguage', payload: lang });
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
export default Context;
export { MainContextProvider };
